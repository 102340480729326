<template>
	<v-card class="d-flex flex-column my-card">
		<v-row no-gutters dense class="d-flex align-start flex-grow-0 pb-6">
			<v-col cols="12">
				<p class="heading-sf20">Password</p>
			</v-col>
		</v-row>
		<v-form v-model="valid" ref="form" lazy-validation>
			<v-row no-gutters dense class="d-flex flex-column">
				<v-row class="pb-4" dense>
					<v-col cols="12" class="pt-0">
						<label>Current Password</label>
					</v-col>
					<v-col cols="12" class="pt-0">
						<v-text-field
							single-line
							outlined
							dense
							:hide-details="Show"
							height="48"
							color="#CACEDA"
							type="password"
							v-model="password_data.password"
							placeholder="Current password"
							:rules="oldpasswordRules"
							required
						></v-text-field>
					</v-col>
				</v-row>

				<v-row class="pb-4" dense>
					<v-col cols="12" class="pt-0">
						<label>New Password</label>
					</v-col>
					<v-col cols="12" class="pt-0">
						<v-text-field
							single-line
							outlined
							dense
							:hide-details="Show"
							height="48"
							color="#CACEDA"
							type="password"
							v-model="password_data.new_password"
							placeholder="New password"
							:rules="passwordRules"
							required
						></v-text-field>
					</v-col>
				</v-row>

				<v-row class="pb-4" dense>
					<v-col cols="12" class="pt-0">
						<label>Confirm New Password</label>
					</v-col>
					<v-col cols="12" class="pt-0">
						<v-text-field
							single-line
							outlined
							dense
							:hide-details="Show"
							height="48"
							color="#CACEDA"
							type="password"
							v-model="password_data.con_password"
							placeholder="Confirm new password"
							:rules="conpasswordRules"
							required
						></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col
						v-if="error_message != ''"
						class="d-flex align-center mt-auto p-0 custom-error"
						cols="12"
						>{{ this.error_message }}</v-col>
				</v-row>
			</v-row>
		</v-form>
		<v-row no-gutters dense align="stretch" class="d-flex">
			<v-col cols="12" class="d-flex justify-end mt-auto">
				<v-btn
					class="my-auto-btn my_elevation"
					id="my_elevation"
					@click="changePassword()"
					:disabled="!valid"
					depressed
				>Save changes</v-btn>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import { mask } from "vue-the-mask";
import { passwordChange } from "../../services/authservices";
export default {
	data() {
		return {
			active_tab: false,
			show: true,
			valid: true,
			error_message: "",
			oldpasswordRules: [
				v => !!v || "Current password is required",
			],
			passwordRules: [
				v => !!v || "New password is required",
				v =>
					(v && v.length >= 8) ||
					"Password must be greater than 8 characters"
			],
			conpasswordRules: [
				v => !!v || "Confirm new password is required",
				v =>
					v == this.password_data.new_password ||
					"New password must match"
			],

			password_data: {
				password: "",
				new_password: "",
				con_password: ""
			}
		};
	},

	created() {
		this.$store.watch(
			state => {
				return state.auth.error;
			},
			() => {
				if (this.active_tab === true) {
					this.error_message = this.$store.state.auth.error;
				}
			},
			{ deep: true }
		);
	},

	computed: {
		Show() {
			return (this.show = !this.valid ? false : true);
		}
	},

	methods: {
		async changePassword() {
			if (this.$refs.form.validate()) {
				this.$store.commit("errorMessage", "");
				this.error_message = "";
				this.active_tab = true;

				let data = await passwordChange(this.password_data);

				if (!data.data) {
					this.error_message = this.$store.state.auth.error;
				} else {
					this.active_tab = false;
					this.$refs.form.reset();
				}
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.password-view {
	height: 100% !important;
}
</style>